import * as React from 'react';
import Card from './card';
import { css } from '@emotion/core';

const AccountOverview = props => {
  // Add the specific card component when they will be built and available
  return (
    <div
      css={css`
        margin-left: 33px;
        margin-top: 40px;
      `}
      data-testid="account__overview-container"
    >
      <h1
        css={css`
          font-size: 40px;
          height: 44px;
          font-family: 'Barlow';
          font-weight: 900;
          letter-spacing: normal;
          font-stretched: condensed;
          margin-bottom: 32px;
        `}
        data-testid="account__overview-title"
      >
        Account Overview
      </h1>
      <Card titleText="First Card" />
      <Card titleText="Second Card" />
      <Card titleText="Third Card" />
    </div>
  );
};

export default AccountOverview;
